import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { Button, DecorativeSquare, ExamForm } from '../../components'
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom'

import css from './AdmisionPage.module.css'
import { LeftArrow } from '../../assets/icons'
import { getQuestions } from '../../model/questions'
import { createAttempt, createResult } from '../../model/results';

const EXAM_ID = '5ca4ca39-bec5-4e9f-84db-18178d9fb39d'

const AdmisionPage = () => {

    const [startExam, setStartExam] = useState(false)
    const [currentSection, setCurrentSection] = useState(0)
    const [question, setQuestion] = useState(null)
    const [attempt, setAttempt] = useState(null)

    const { user, error } = useAuth();
    const navigate = useNavigate();

    if (error) {
        navigate('/login')
    }

    useEffect(() => {
        const fetchQuestions = async () => {
            const prompt = `Hola soy ${user?.name}, mi ultimo grado de estudios es ${user?.profile?.education_level}, mis hobbies son ${user?.profile?.hobbies} y me gustaría presentar mi examen de admisión.`

            const response = await getQuestions({
                chatId: user?.id_current_session,
                question: prompt
            })

            let questionObject = JSON.parse(response.data.text)
            
            setQuestion(questionObject)
        }

        const generateAttempt = async () => {
            const body = {
                user_id: user?.id_user,
                examId: EXAM_ID
            }

            const response = await createAttempt(body)

            setAttempt(response.data)
        }

        if(startExam === true) {
            fetchQuestions()

            if(attempt === null) {
                generateAttempt()
            }
        }
    }, [startExam])

    const handleSubmitResponse = async (values) => {

        const body = {
            id_attempt: attempt.id_attempt,
            id_user: user?.id_user,
            ...values[0],
            correct_answer: question.correctas
        }
        
        await createResult(body).then((response) => {
            //reset form
            document.getElementById('admision-exam').reset()
            setQuestion(null)
        })

        const newQuestion = await getQuestions({
            chatId: user?.id_current_session,
            question: body.answer_id
        })
        
        let questionObject = JSON.parse(newQuestion.data.text)
        console.log("new questionObject", questionObject)
        setQuestion(questionObject)
        setCurrentSection(currentSection + 1)
    }
    
    return (
        <div className={css.container}>
            <header className={classNames(css.header, {
                [css.compress]: startExam === true
            })}>
                <DecorativeSquare color="#26d07c" positionTop="16%" positionRight="100px" hide={startExam}/>
                <DecorativeSquare color="#007864" positionTop="50%" positionRight="0px" hide={startExam}/>
                <DecorativeSquare color="#00a499" positionTop="50%" positionLeft="0" hide={startExam}/>
                <a className={css.breadcrumb} href="/">
                    <LeftArrow /><span>Atrás</span>
                </a>
                <h1>Examen de admisión</h1>
                <p>
                    Bienvenido al examen de admisión, a continuación se presentarán una serie de preguntas que deberás responder de acuerdo a tus preferencias.
                </p>
            </header>
            {startExam === false && (
                <section className={css.exam}>
                    <h2>INSTRUCCIONES</h2>
                    <p>
                        A continuación se presentarán una serie de preguntas que deberás responder de acuerdo a tus preferencias.
                    </p>
                    <Button
                        className={css.button}
                        text="Comenzar"
                        onClick={() => {
                            setStartExam(true)
                        }}
                    />
                </section>
            )}

            {(startExam === true && !question && !question?.final) && (
                <div className={css.spinnerContainer}>
                    <div className={css.spinner} />
                </div>
            )}
            
            {(startExam === true && !!question && !question?.final) && (
                <div className={css.exam}>
                    <ExamForm
                        formId="admision-exam"
                        handleSubmit={(values) => {                        
                            handleSubmitResponse(values)  
                        }}
                        section={!!question ? { questions: [
                            {
                                id_question: currentSection,
                                label: question.preguntas,
                                options: question.respuestas.split('\\').map((item, index) => {
                                    return {
                                        id_option: item.trim()[0],
                                        label: item
                                    }
                                }),
                                question_type: 'radio'
                            }
                        ]} : null}
                        currentSection={currentSection}
                        setCurrentSection={() => setCurrentSection(currentSection + 1)}
                        totalSections={null}
                    />
                </div>
            )}

            {(startExam === true && !!question && question?.final) && (
                <div className={css.exam}>
                    <h2>¡Felicidades!</h2>
                    <p>
                        Has terminado el examen de admisión, comunicate con tu asesor para continuar el proceso.
                    </p>
                    <Button
                        className={css.button}
                        text="Finalizar"
                        onClick={() => {
                            navigate('/')
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default AdmisionPage